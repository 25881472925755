html{
  background-color: #282c34;
}
.App {
    background-color: #282c34;
    position: relative;
    text-align: center;  
    min-height: 100vh;
  }

  .App-header {
    position: relative;
    background-color: #282c34;
    min-height: 100vh;
    min-width: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-bottom: 20px;
  }

  .round-image::before {
    content: '';
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 100px;
    background-image: url('./components/320x320.jpg'); /* Passe den Pfad zum Bild an */
    background-size: cover;
    border-radius: 50%;
    z-index: 1; /* Über der Überschrift anzeigen */
  }

  h1{
    margin-top: 90px; 
  }

  
  .Link-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  
  .Link-button {
    
    background-color: #0e5496;
    color: white;
    border: none;
    font-size: larger;
    padding: 15px 30px;
    margin: 6px 0;
    cursor: pointer;
    width: 300px;
  }
