/* Farbdefinitionen */
:root {
  --primary-color: #0e5496;
  --text-color: rgba(59, 130, 246, 0.5);
}

/* Gemeinsame Stile */
*,
::before,
::after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

body {
  margin: 0;
  overflow: hidden;
  line-height: 1.5;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* App-Stile */
.App {
  display: flex;
  min-height: 100vh;
  padding-left: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--text-color);
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.round-image::before {
  padding-left: 20px;
  position: absolute;
  top: 2.5rem;
  left: 50%;
  z-index: 10;
  height: 6rem;
  width: 6rem;
  transform: translateX(-50%);
  border-radius: 50%;
  background-size: cover;
  content: '';
  background-image: url('./components/320x320.jpg');
}

h1 {
  padding-left: 0;
  margin-top: 2rem;
  font-size: 2rem;
  line-height: 1.75rem;
  font-weight: 700;
}

.Link-list {
  margin-top: 1.5rem;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* NeumorphismButton-Stile */
.NeumorphismButton {
  font-size: 19px;
  width: 200px;
  height: 60px;
  padding: 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: white;
  background-color: var(--primary-color);
  box-shadow: -3px -3px 5px #166380, 6px 6px 10px #00000040;
  transition: box-shadow 0.3s;
  margin-bottom: 10px;
}

.NeumorphismButton:hover {
  box-shadow: -1px -1px 3px rgba(255, 255, 255, 0.6), 1px 1px 3px rgba(0, 0, 0, 0.3),
    inset -2px -2px 3px rgba(255, 255, 255, 1), inset 2px 2px 3px rgba(0, 0, 0, 0.3);
}


/* Media Query für kleine Bildschirme (max. 600px Breite) */

@media only screen and (max-width: 600px) {
  .App {
    padding-left: 8px;
  }

  .round-image::before {
    top: 1.5rem;
    height: 4rem;
    width: 4rem;
  }

  h1 {
    margin-top: 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .Link-list {
    margin-top: 1rem;
  }

  .NeumorphismButton {
    width: 200px;
    height: 60px;
    padding: 8px;
    font-size: 20px; /* Neue Schriftgröße für kleine Bildschirme hinzugefügt */
    margin-bottom: 1rem; /* Verhindert zu geringen Abstand zwischen Buttons */
  }
}
